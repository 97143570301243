
import { defineComponent, ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import axios from '../axios'
import { User } from "@/classes/user";
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  components: {},
  setup () {
    const isActive = ref('')
    const store = useStore();
    const router = useRouter();
    const sample = ref('');
    


    const auth_user = computed(() => store.getters["AuthUser/user"]);
    return {
      isActive,
      auth_user,

    }
  },
});
