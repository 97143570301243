
import Sidebar from "./Sidebar.vue"
import Header from "./Header.vue"
import { defineComponent, ref, onMounted, computed } from 'vue';

import axios from '../axios'
import { useStore } from 'vuex';
import { User } from "@/classes/user";

export default defineComponent({
  components: {
    Sidebar,
    Header
  },
  setup() {
    const store = useStore();
    const getUser = async () => {
        const url = 'auth/user';
        const response = await axios.get(url);
        const u: User = response.data.data;
        await store.dispatch('AuthUser/setUser', new User(
          u.id,
          u.email,
          u.last_name,
          u.first_name,
          u.role,
          u.sidebar
        ));

    };

    onMounted(getUser);

    const auth_user = computed(() => store.getters["AuthUser/user"]);

    return {
      selectedKeys: ref<string[]>(['0']),
      collapsed: ref<boolean>(false),
      auth_user,
    };
  },
});
