
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import axios from '../axios'

export default defineComponent({
  setup() {
    const store = useStore();
    
    const logout = async () => {
      window.localStorage.removeItem("DWSR_TOKEN");
      await axios.post('auth/logout');
      window.location.href = "/login";
    };

    const auth_user = computed(() => store.state.AuthUser.user);

    return {
      logout,
      auth_user,
    };
  },
});
